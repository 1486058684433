import React, { useState, useEffect } from "react";
import { Grid2, Button, Container } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Person from "./Person";
import "./People.css";
import YC from "./images/YC.png";
import fellowship from "./images/fellowship.png";
import TKS from "./images/TKS.png";
import cansbridge from "./images/cansbridge.png";
import cansbridge2 from "./images/cansbridge2.png";
import socratica from "./images/socratica.png";
import tree from "./images/tree.png";

function People() {
  const [peopleData, setPeopleData] = useState([]);

  var organizations = [YC, fellowship, TKS, cansbridge, cansbridge2, socratica];

  useEffect(() => {
    fetch("/people.json")
      .then((response) => response.json())
      .then((data) => setPeopleData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <div className="peopleHeaderBlock">
        <div className="peopleHeaderText">
          Join <span className="italicizedHeaderText">all of us</span> building
          the future in Vancouver.
        </div>
        <div className="peopleSubHeaderText">
          Anyone can be added to the V2 collective by launching significant
          projects in technology, art, or science in Vancouver or at a
          V2-affiliated event.
        </div>
      </div>
      <div className="people-flex-container">
        {peopleData.map((person) => (
          <div className="flex-item">
            <Person name={person.name} description={person.description} />
          </div>
        ))}
      </div>
      <div className="orgText">From organizations such as</div>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Grid2
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
        >
          {organizations.map((organization) => (
            <Grid2 item xs={12} sm={6} md={4} justifyContent="center">
              <img src={organization} className="orgImage"></img>
            </Grid2>
          ))}
        </Grid2>
      </Container>
      <div className="footerDiv">
        <Button
          variant="outlined"
          href="https://tally.so/r/w2xKLL"
          style={{
            fontSize: "21px",
            borderColor: "#3CB3EB",
            borderRadius: "0px",
            color: "white",
            maxWidth: "150px",
            maxHeight: "50px",
            minWidth: "150px",
            minHeight: "50px",
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#3CB3EB",
          }}
          endIcon={<ArrowForwardIcon />}
        >
          JOIN US
        </Button>
        <img src={tree} className="footerImage"></img>
      </div>
    </div>
  );
}

export default People;
