import React from "react";
import { Helmet } from "react-helmet";

function MetaTags() {
  return (
    <div>
      <Helmet>
        <title>V2 — Making Vancouver A Place To Build The Future</title>
        <meta
          name="description"
          content="An open collective for people in Vancouver building extraordinary things in technology, art, and science."
        />
        <meta
          name="keywords"
          content="Vancouver Founders,Vancouver Makers,Vancouver Coworking"
        />
        <link rel="canonical" href="https://v2.city" />

        <meta
          property="og:title"
          content="V2 — Making Vancouver A Place To Build The Future"
        />
        <meta
          property="og:description"
          content="An open collective for people in Vancouver building extraordinary things in technology, art, and science."
        />
        <meta property="og:image" content="/v2city_ogimage.png" />
        <meta property="og:url" content="https://v2.city" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="V2 — Making Vancouver A Place To Build The Future"
        />
        <meta
          name="twitter:description"
          content="An open collective for people in Vancouver building extraordinary things in technology, art, and science."
        />
        <meta name="twitter:image" content="/v2city_ogimage.png" />
        <meta name="twitter:url" content="https://v2.city" />

        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta name="twitter:site" content="@v2_vancouver" />
        <meta name="twitter:creator" content="@v2_vancouver" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
    </div>
  );
}

export default MetaTags;
