import './AppToolbar.css';
import React from 'react';

function AppToolbar() {
  return (
    <div className="AppBar">
      <img src="v2.svg" className="logo" />
      <div className='titleText'>An open collective for <span class="stylizedToolbarHeaderText">technology</span>, <span class="stylizedToolbarHeaderText">art</span> and <span class="stylizedToolbarHeaderText">science.</span></div>
    </div>
  );
}

export default AppToolbar;