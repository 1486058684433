import './Person.css';

function Person(props) {
  const { name, description } = props;

  return (
    <div className='block'>
      <div className='nameText'>[{name}]</div>
      <div className='description'>{description}</div>
    </div>
  )
}

export default Person;