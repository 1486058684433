import residency from "./images/residency.png";
import demos from "./images/demos.png";
import sideQuest from "./images/sideQuest.png";
import "./Initiatives.css";
import { Grid2, Button, Container } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function CurrentInitiatives() {
  return (
    <div>
      <div className="initiativesHeaderText">
        Things happening <span class="initiativesStylizedText">now</span> :
      </div>

      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "100px",
        }}
      >
        <Grid2 container spacing={8} justifyContent="center">
          <Grid2
            item
            xs={12}
            sx={{ marginLeft: 4, marginRight: 4 }}
            sm={6}
            md={4}
            justifyContent="center"
          >
            <div className="blockStyling">
              <img src={residency} className="imgStyling"></img>
              <p className="descriptionText">
                Go full-time on your ideas in a house designed for building
                extraordinary things. Starting January 2025, join in a 4-month
                cycle or participate in our events & programming.
              </p>
              <Button
                style={{ color: "#3CB3EB" }}
                sx={{
                  borderRadius: "2px",
                  borderColor: "black",
                  marginTop: "15px",
                }}
                variant="outlined"
                size="large"
                href="/residency"
                endIcon={<ArrowForwardIcon />}
              >
                APPLY NOW
              </Button>
            </div>
          </Grid2>

          <Grid2
            item
            xs={12}
            sx={{ marginLeft: 4, marginRight: 4 }}
            sm={6}
            md={4}
            justifyContent="center"
          >
            <div className="blockStyling">
              <img src={sideQuest} className="imgStyling"></img>
              <p className="descriptionText">
                Embark on a creative quest with your team to build an idea over
                a weekend that helps shape the future of Vancouver
              </p>
              <Button
                style={{ color: "#3CB3EB" }}
                sx={{
                  borderRadius: "2px",
                  borderColor: "black",
                  marginTop: "15px",
                }}
                variant="outlined"
                size="large"
                href="https://lu.ma/9a38q8j0"
                endIcon={<ArrowForwardIcon />}
              >
                APPLY NOW
              </Button>
            </div>
          </Grid2>
        </Grid2>
      </Container>
    </div>
  );
}

export default CurrentInitiatives;
