import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AppToolbar from "./AppToolbar";
import Hero from "./Hero";
import People from "./People";
import Story from "./Story";
import MetaTags from "./MetaTags";
import Initiatives from "./Initiatives";
import CurrentInitiatives from "./CurrentInitiatives";
import Residency from "./Residency";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <MetaTags/> */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="gridBg"></div>
                <div className="heroPage">
                  <div className="heroImage"></div>
                  <AppToolbar />
                  <Hero />
                </div>
                <div className="currentInitiatives">
                  <CurrentInitiatives />
                </div>
                <div className="initiatives">
                  <Initiatives />
                </div>
                <div className="storyPage">
                  <div className="storyImage"></div>
                  <Story />
                </div>
                <div className="people">
                  <People />
                </div>
              </>
            }
          />
          <Route path="/residency" element={<Residency />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
