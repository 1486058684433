import residency from "./images/residency.png";
import demos from "./images/demos.png";
import salon from "./images/salon.png";
import "./Initiatives.css";
import { Grid2, Button, Container } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function Initiatives() {
  return (
    <div>
      <div className="initiativesHeaderText">
        Things coming up:
      </div>

      <Container
      className="containerStyle"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Grid2 container spacing={8} justifyContent="center">
          <Grid2 item xs={12} sx={{ marginLeft: 4, marginRight: 4 }} sm={6} md={4} justifyContent="center">
            <div className="blockStyling">
              <img src={demos} className="imgStyling"></img>
              <p className="descriptionText">
                A curated demo line-up showcasing Vancouver’s best talent at the
                intersection of art, science and technology.
              </p>
              <p
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  marginTop: "45px",
                }}
              >
                COMING SUMMER 2025
              </p>
            </div>
          </Grid2>

          <Grid2 item xs={12} sx={{ marginLeft: 4, marginRight: 4 }} sm={6} md={4} justifyContent="center">
            <div className="blockStyling">
              <img src={salon} className="imgStyling"></img>
              <p className="descriptionText">
                Gatherings for knowledge transfer and practice across important
                topics of the future, relevant to all disciplines.
              </p>
              <p
                style={{
                  color: "#808080",
                  fontSize: "14px",
                  marginTop: "45px",
                }}
              >
                SOON FOR EARLY SUPPORTERS
              </p>
            </div>
          </Grid2>
        </Grid2>
      </Container>
      <div className="footerText">
        Growth emerges from the intersection of people creating across
        disciplines and experience levels. <br /> V2 is the{" "}
        <span className="storyStylizedText">bridge</span>.
      </div>
    </div> 
  );
}

export default Initiatives;
