import "./Hero.css";
import React from "react";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const theme = createTheme({
  typography: {
    fontFamily: '"Newsreader"',
    marginBottom: 0,
    marginTop: 0,
  },
});

function Hero() {
  return (
    <div>
      <div className="titleBlock">
        <div className="heroDescriptiontext">
          V2 IS THE BRIDGE FOR <br /> PEOPLE MAKING THINGS <br /> ACROSS
          VANCOUVER
        </div>
        <div className="heroHeader">VANCOUVER</div>
        <div className="heroSubHeader">
          A place to build the{" "}
          <span className="stylizedHeroHeaderText">future</span>
        </div>

        <Button
          variant="outlined"
          className="subscribeButton"
          href="https://tally.so/r/w2xKLL"
          style={{
            fontSize: "21px",
            borderColor: "#3CB3EB",
            borderRadius: "0px",
            color: "white",
            maxWidth: "150px",
            maxHeight: "50px",
            minWidth: "150px",
            minHeight: "50px",
            marginTop: "70px",
            backgroundColor: "#3CB3EB",
          }}
          endIcon={<ArrowForwardIcon />}
        >
          JOIN US
        </Button>
      </div>
    </div>
  );
}

export default Hero;
