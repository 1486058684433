import "./Story.css";

function Story() {
  return (
    <div className="storyTextContent">
      <div className="storyHeaderText">
        It's time to tell a <span class="storyStylizedText">new</span> story
      </div>
      <div className="storySubHeaderText">
        Vancouver is a place of latent potential. <br />
        <br />
        Our city has deep understanding of scientific research, the environment,
        music, art, fashion, film, multiculturalism, and open technology. <br />
        <br />
        By amplifying the creative spirit of people who are building
        extraordinary things here, we can deeply impact the future of humanity.
      </div>
    </div>
  );
}

export default Story;
